/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable */
import React, { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../assets/svgIcons/sidebarNav/dashboard.svg';
import { ReactComponent as AppointmentIcon } from '../assets/svgIcons/sidebarNav/appointment.svg';
import { ReactComponent as CalendartIcon } from '../assets/svgIcons/sidebarNav/calendar.svg';
import { ReactComponent as ClassestIcon } from '../assets/svgIcons/sidebarNav/classes.svg';
import { ReactComponent as ClientstIcon } from '../assets/svgIcons/sidebarNav/clients.svg';
import { ReactComponent as ReportstIcon } from '../assets/svgIcons/sidebarNav/reports.svg';
import { ReactComponent as SalesIcon } from '../assets/svgIcons/sidebarNav/sales.svg';
import { ReactComponent as StaffIcon } from '../assets/svgIcons/sidebarNav/staff.svg';
import { ReactComponent as StudiosIcon } from '../assets/svgIcons/sidebarNav/studios.svg';
import { ReactComponent as SettingsIcon } from '../assets/svgIcons/sidebarNav/settings.svg';
import { ReactComponent as MyProfileIcon } from '../assets/svgIcons/sidebarNav/myprofile.svg';

import { ReactComponent as CompanyDetailsIcon } from '../assets/svgIcons/administratorSettings/companyDetails.svg';
import { ReactComponent as ReportSettingsIcon } from '../assets/svgIcons/administratorSettings/reportSettings.svg';
import { ReactComponent as ClassesSettingsIcon } from '../assets/svgIcons/administratorSettings/classesSettings.svg';

import { ReactComponent as PeakNonPeakHoursIcon } from '../assets/svgIcons/administratorSettings/peakNonPeakHours.svg';
import { ReactComponent as ClientWaiverIcon } from '../assets/svgIcons/administratorSettings/clientWaiver.svg';
import { ReactComponent as StudiosSettingsIcon } from '../assets/svgIcons/administratorSettings/studiosSettings.svg';
// import { ReactComponent as StaffPermissionIcon } from '../assets/svgIcons/administratorSettings/staffPermission.svg';
import { ReactComponent as StaffPayRatesIcon } from '../assets/svgIcons/administratorSettings/staffPayrates.svg';
import { ReactComponent as StaffMembersIcon } from '../assets/svgIcons/administratorSettings/staffMembers.svg';
import { ReactComponent as PackagesSettingsIcon } from '../assets/svgIcons/administratorSettings/packagesSettings.svg';
import { ReactComponent as FitnessClassTemplatesIcon } from '../assets/svgIcons/administratorSettings/fitnessClassTemplates.svg';
import { ReactComponent as EmailSettingsIcon } from '../assets/svgIcons/administratorSettings/emailSettings.svg';

import { ReactComponent as AccountDetailsIcon } from '../assets/svgIcons/soloClientSettings/accountDetails.svg';
import { ReactComponent as NotificationsIcon } from '../assets/svgIcons/soloClientSettings/notifications.svg';
import { ReactComponent as PackagesIcon } from '../assets/svgIcons/soloClientSettings/packages.svg';
import { ReactComponent as PaymentIcon } from '../assets/svgIcons/soloClientSettings/payment.svg';
import { ReactComponent as WaiverIcon } from '../assets/svgIcons/soloClientSettings/waiver.svg';
import { ReactComponent as PoliciesProceduresIcon } from '../assets/svgIcons/soloClientSettings/policiesProcedures.svg';
import { ReactComponent as UserIcon } from '../assets/svgIcons/soloClientSettings/user-icon.svg';
// import { ReactComponent as PromoCodeIcon } from '../assets/svgIcons/administratorSettings/pricingTag.png';
import ClientWaiverProvider from '../contexts/clientWaiverContext';
import StudioProvider from '../contexts/studioContext';
import StudioListProvider from '../contexts/studioListContext';
import PromoCodeProvider from '../contexts/promoCodeContext';
import HoursProvider from '../contexts/hoursContex';
import { isSolo } from '../utils';
import NotFound from '../screens/NotFound';
import ClientReports from '../screens/Main/ClientReports/ClientReports';

const TimeZoneProvider = lazy(() => import('../contexts/TimeZoneContext'));
const CompaniesProvider = lazy(() => import('../contexts/companiesContex'));
const CompanyProvider = lazy(() => import('../contexts/companyContext'));
const ClassCategoriesProvider = lazy(() => import('../contexts/classCategoriesContext'));
const RevenueCategoriesProvider = lazy(() => import('../contexts/revenueCategoriesContext'));
const TaxDetailsProvider = lazy(() => import('../contexts/taxDetailsContext'));
const StateProvider = lazy(() => import('../contexts/StateContext'));
const CancelPolicyProvider = lazy(() => import('../contexts/cancelPolicyContex'));
const ValidateSettingsProvider = lazy(() => import('../contexts/ValidateSettingsContext'));
const StaffPayratesProvider = lazy(() => import('../contexts/StaffPayratesContext'));
const EmailSettingsProvider = lazy(() => import('../contexts/EmailSettingsContext'));
const StaffMemberProvider = lazy(() => import('../contexts/staffMemberContext'));
const MembershipProvider = lazy(() => import('../contexts/membershipContext'));
const ClassPackProvider = lazy(() => import('../contexts/classPackContext'));
const UnsaveAlertProvider = lazy(() => import('../contexts/unsaveAlertContext'));
const AlertProvider = lazy(() => import('../contexts/alertContext'));
const AppUtilitiesProvider = lazy(() => import('../contexts/appUtilitiesContext'));
const FitnessClassProvider = lazy(() => import('../contexts/fitnessClassContext'));
const ClassInstanceProvider = lazy(() => import('../contexts/classInstanceContext'));
const SoloInstructorProvider = lazy(() => import('../contexts/soloInstructorContext'));
const ClientSignupProvider = lazy(() => import('../contexts/ClientSignupContext'));
const Main = lazy(() => import('../screens/Main'));
const Dashboard = lazy(() => import('../screens/Main/Dashboard'));
const CustomerInsights = lazy(() => import('../screens/Main/Dashboard/CustomerInsights'));
const Widget = lazy(() => import('../screens/Widget'));
const Calendar = lazy(() => import('../screens/Main/Calendar'));
const Classes = lazy(() => import('../screens/Main/Classes'));
const ABPClasses = lazy(() => import('../screens/Main/ABPClasses'));
const Clients = lazy(() => import('../screens/Main/Clients'));
const ClientsCodeRefactoring = lazy(() => import('../screens/Main/Clients/ClientsCodeRefactoring'));
const Reports = lazy(() => import('../screens/Main/Reports'));
const Sales = lazy(() => import('../screens/Main/Sales'));
const Staff = lazy(() => import('../screens/Main/Staff'));
const Appointment = lazy(() => import('../screens/Main/Appointment'));
const Studios = lazy(() => import('../screens/Main/Studios'));
const Settings = lazy(() => import('../screens/Main/Settings'));
const ContractTermsProvider = lazy(() => import('../contexts/contractTermsContext'));

const CompanyDetails = lazy(() => import('../screens/AdminComponents/CompanyDetails'));
const ReportSettings = lazy(() => import('../screens/AdminComponents/ReportingSettings'));
const ClassesSettings = lazy(() => import('../screens/AdminComponents/ClassesSettings'));
const ClientWaiver = lazy(() => import('../screens/AdminComponents/ClientWaiver'));
const StudiosSetting = lazy(() => import('../screens/AdminComponents/Studios'));
const PromoCode = lazy(() => import('../screens/AdminComponents/PromoCode'));
const Instructors = lazy(() => import('../screens/AdminComponents/Instructors'));
// const StaffPermission = lazy(() => import('../screens/AdminComponents/StaffPermission'));
const StaffPayrates = lazy(() => import('../screens/AdminComponents/StaffPayrates'));
const Email = lazy(() => import('../screens/AdminComponents/EmailComponent'));
const FitnessClassTemplate = lazy(() => import('../screens/AdminComponents/FitnessClassTemplate'));
const Packages = lazy(() => import('../screens/AdminComponents/Packages'));
const Membership = lazy(() => import('../screens/AdminComponents/Membership'));
const ClassPack = lazy(() => import('../screens/AdminComponents/ClassPack'));
const StaffMembersList = lazy(() => import('../screens/AdminComponents/StaffMembersList'));
const StaffMember = lazy(() => import('../screens/AdminComponents/StaffMember'));

const Hours = lazy(() => import('../screens/AdminComponents/HoursComponent'));
const ClientSignup = lazy(() => import('../screens/Widget/ClientSignup/ClientSignup'));
const LoginWrapper = lazy(() => import('../screens/Widget/LoginWrapper'));
const CheckInWrapper = lazy(() => import('../screens/Widget/CheckInWrapper'));
const CheckInResponse = lazy(() => import('../screens/Auth/Login/CheckInResponse'));
const BookEvent = lazy(() => import('../screens/Auth/Login/BookEvent'));
const BookedEvent = lazy(() => import('../screens/Auth/Login/BookedEvent'));
const CancelEvent = lazy(() => import('../screens/Auth/Login/CancelEvent'));
const FullyBookedEvent = lazy(() => import('../screens/Auth/Login/FullyBookedEvent'));
const ClientSignUpQrCode = lazy(() => import('../screens/Widget/ClientSignup/ClientSignUpQrCode'));
const ForgotPasswordWrapper = lazy(() => import('../screens/Widget/ForgotPasswordWrapper'));
const ResetPasswordWrapper = lazy(() => import('../screens/Widget/ResetPasswordWrapper'));
const UpcomingClasses = lazy(() => import('../screens/Main/Calendar/UpcomingClasses'));
const ContractTerms = lazy(() => import('../screens/AdminComponents/ContractTerms'));

const UberDashboard = lazy(() => import('../screens/Dashboard'));

/* Solo Client Screens */

const SoloClient = lazy(() => import('../screens/SoloClient/SoloClient'));
const SoloClientSettings = lazy(() => import('../screens/SoloClient/ClientSettings'));

const AccountDetails = lazy(() =>
  import('../screens/Widget/ClientSignup/SoloClient/AccountDetails'),
);

const ABIAccountDetails = lazy(() =>
  import('../screens/Widget/ClientSignup/SoloClient/ABIAccountDetails'),
);
const Notifications = lazy(() => import('../screens/Widget/ClientSignup/SoloClient/Notifications'));
const SoloClientPackages = lazy(() => import('../screens/Widget/ClientSignup/SoloClient/Packages'));

const ABIClientPackages = lazy(() =>
  import('../screens/Widget/ClientSignup/SoloClient/ABIPackages'),
);
const Payment = lazy(() => import('../screens/Widget/ClientSignup/SoloClient/Payment'));
const ABIPayment = lazy(() => import('../screens/Widget/ClientSignup/SoloClient/ABIPayment'));
const SoloClientWaiver = lazy(() => import('../screens/Widget/ClientSignup/SoloClient/Waiver'));
const SoloClientTerms = lazy(() => import('../screens/Widget/ClientSignup/SoloClient/Terms'));
const Abc = lazy(() => import('../screens/Widget/ClientSignup/Abc.js'));

const ClientDashboard = lazy(() => import('../screens/ClientDashboard'));

const PrivateRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { company } = useParams();

  useEffect(() => {
    /* let intervalId = setInterval(() => {
      // console.log("Interval ")
      const currentUrl = window.location.href;
      const user = Cookies.get('user');
      const token = Cookies.get('token');
      let loginURL = '/login';
      if (currentUrl?.includes('solo-instructor')) {
        loginURL = `/${company}/solo-instructor/login`;
      }
      if (currentUrl?.includes('solo-client')) {
        loginURL = `/${company}/solo-client/client/app/login`;
      }

      if (!user) {
        if (currentUrl.includes('/login') == false) {
          clearInterval(intervalId);

          Cookies.remove('token');
          Cookies.remove('hosttype');
          Cookies.remove('user');

          localStorage.clear('clientsTabQueryData');

          dispatch({ type: 'USER', payload: {} });

          navigate(loginURL);
        }
      }

      if (!token) {
        clearInterval(intervalId);
        navigate(loginURL);
      }
    }, 1000); */
    if (!Cookies.get('user') || !Cookies.get('token')) {
      const currentUrl = window.location.href;
      let loginURL = '/login';
      if (currentUrl?.includes('solo-instructor')) {
        loginURL = `/${company}/solo-instructor/login`;
      }
      if (currentUrl?.includes('solo-client')) {
        loginURL = `/${company}/solo-client/client/app/login`;
      }
      // console.log('loginURL', 'appRoutes', loginURL);
      navigate(loginURL);
    }
  }, [Cookies.get('token'), Cookies.get('user'), navigate]);

  return <Component {...props} />;
};

const isABP = true; // menu visible in abp
const isHide = true; // menu hide in solo-instructor
const userCookie = Cookies.get('hosttype');
let userType = 'company';
if (userCookie) {
  let buff = new Buffer(userCookie, 'base64');
  userType = buff.toString('ascii');
} else {
}
/* Check apb lite user */
const abpSpecial = Cookies.get('isSpecial') || '0';

/* Check apb lite user end */
export const settingsNav = [
  {
    name: isSolo() ? 'Instructor Details' : 'Company Details',
    // image: require('../assets/images/company-details.jpg').default,
    imageIcon: <CompanyDetailsIcon />,
    path: 'companyDetails',
    element: <CompanyDetails />,
    isDisabled: false,
  },
  {
    name: 'Services and Products',
    /* image: require('../assets/images/reporting-settings.jpg').default, */
    imageIcon: <ReportSettingsIcon />,
    path: 'reportSettings',
    element: <ReportSettings />,
    isDisabled: false,
    isABP,
  },
  {
    name: 'Sessions Settings',
    /* image: require('../assets/images/classes-settings.jpg').default, */
    imageIcon: <ClassesSettingsIcon />,
    path: 'classesSettings',
    element: <ClassesSettings />,
    isDisabled: false,
    isABP,
  },
  {
    name: 'Peak/Non Peak Hours',
    /* image: require('../assets/images/peak-nonpeak-hours.jpg').default, */
    imageIcon: <PeakNonPeakHoursIcon />,
    path: 'hours',
    element: <Hours />,
    isDisabled: false,
  },
  {
    name: 'Client Waiver',
    /* image: require('../assets/images/client-waiver.jpg').default, */
    imageIcon: <ClientWaiverIcon />,
    path: 'clientWaiver',
    element: <ClientWaiver />,
    isDisabled: false,
  },
  {
    name: 'Policies & Procedures',
    /* image: require('../assets/images/client-waiver.jpg').default, */
    imageIcon: <ClientWaiverIcon />,
    path: 'contractTerms',
    element: <ContractTerms />,
    isDisabled: false,
  },
  {
    name: 'Studios',
    /* image: require('../assets/images/studios.jpg').default, */
    imageIcon: <StudiosSettingsIcon />,
    path: 'studios',
    element: <StudiosSetting />,
    isDisabled: false,
    isABP,
  },
  // {
  //   name: 'Staff Permission',
  //   /* image: require('../assets/images/staff-permissions.jpg').default, */
  //   imageIcon: <StaffPermissionIcon />,
  //   path: 'staffPermission',
  //   element: <StaffPermission />,
  //   isDisabled: true,
  //   isABP,
  // },
  {
    name: 'Staff Payrates',
    /* image: require('../assets/images/staff-payrates.jpg').default, */
    imageIcon: <StaffPayRatesIcon />,
    path: 'staffPayRates',
    element: <StaffPayrates />,
    isDisabled: isSolo(),
    isHide,
  },
  {
    name: 'Staff Members',
    /* image: require('../assets/images/staffMembers.jpg').default, */
    imageIcon: <StaffMembersIcon />,
    path: 'staffMembers',
    element: <StaffMembersList />,
    isDisabled: isSolo(),
    childPath: ['add', 'edit'],
    isABP,
    isHide,
  },
  {
    name: 'Packages',
    /* image: require('../assets/images/packages.jpg').default, */
    imageIcon: <PackagesSettingsIcon />,
    path: 'packages',
    element: <Packages />,
    isDisabled: false,
    childPath: [
      'membership',
      'membership/add',
      'membership/edit',
      'membership/copy',
      'class-pack',
      'class-pack/add',
      'class-pack/edit',
      'class-pack/copy',
    ],
  },
  {
    name: 'Promo-Codes',
    /* image: require('../assets/images/studios.jpg').default, */
    imageIcon: <StaffPayRatesIcon />,
    path: 'promoCodes',
    element: <PromoCode />,
    isDisabled: false,
    isABP,
  },
  {
    name: 'Instructors',
    imageIcon: <StaffMembersIcon />,
    path: 'instructors',
    element: <Instructors />,
    // isHide: !isSolo() || abpSpecial === '1',
    //isDisabled: !isSolo(),
    isABP,
  },
  {
    name: 'Facilitators',
    imageIcon: <StaffMembersIcon />,
    path: 'fascilitators',
    element: <Instructors />,
    // isHide: !isSolo() || abpSpecial === '1',
    //isDisabled: !isSolo(),
    isABP,
  },
  {
    name: 'Templates',
    /* image: require('../assets/images/fitness-class-templates.jpg').default, */
    imageIcon: <FitnessClassTemplatesIcon />,
    path: 'fitnessClassTemplate',
    element: <FitnessClassTemplate />,
    isDisabled: false,
    isABP,
  },
  {
    name: 'Email Settings',
    /* image: require('../assets/images/email-settings.jpg').default, */
    imageIcon: <EmailSettingsIcon />,
    path: 'email',
    element: <Email />,
    isDisabled: false,
    isABP,
  },
];
/* Setting routes without menu list and setting page icon */
export const settingsRoutes = [
  {
    path: 'staffMembers/add',
    element: <StaffMember />,
  },
  {
    path: 'staffMembers/edit/:staffId',
    element: <StaffMember />,
  },
  {
    path: 'packages/membership',
    element: <Membership />,
  },
  {
    path: 'packages/membership/edit/:membershipId',
    element: <Membership />,
  },
  {
    path: 'packages/membership/:copy/:membershipId',
    element: <Membership />,
  },
  {
    path: 'packages/class-pack',
    element: <ClassPack />,
  },
  {
    path: 'packages/class-pack/edit/:classPackId',
    element: <ClassPack />,
  },
  {
    path: 'packages/class-pack/:copy/:classPackId',
    element: <ClassPack />,
  },
];

const soloMainRoutes = [
  {
    path: 'clients/profile/:clientId/:userId',
    element: <ClientDashboard />,
  },
  {
    path: 'client-insights',
    element: <CustomerInsights />,
  },
  {
    path: '/clientreports',
    element: <ClientReports />,
  },
];
const testNav = [
  {
    path: 'classes/details/:classInstanceId',
    element: <ABPClasses />,
  },
];

export const appNav = [
  {
    name: 'Dashboard',
    image: require('../assets/images/dashboard.png').default,
    navIcons: <DashboardIcon />,
    path: '',
    element: <Dashboard />,
    /* isDisabled: isSolo(), */
  },
  {
    name: 'Appointment',
    /* navImage: require('../assets/images/appointments.png').default, */
    image: require('../assets/images/appointments.png').default,
    navIcons: <AppointmentIcon />,
    path: 'appointment',
    element: <Appointment />,
    isDisabled: isSolo(),
  },
  {
    name: 'Calendar',
    /* navImage: require('../assets/images/calendar.png').default, */
    image: require('../assets/images/calendar.png').default,
    navIcons: <CalendartIcon />,
    path: 'calendar',
    element: <Calendar />,
  },
  {
    name: 'Classes',
    /* navImage: require('../assets/images/classes.png').default, */
    image: require('../assets/images/classes.png').default,
    navIcons: <ClassestIcon />,
    path: 'classes/:classInstanceId',
    element: <Classes />,
    isDisabled: isSolo(),
  },
  {
    name: 'Clients',
    /* navImage: require('../assets/images/clients.png').default, */
    image: require('../assets/images/clients.png').default,
    navIcons: <ClientstIcon />,
    path: 'clients',
    element: <Clients />,
    // children:[
    //   clientProfile
    // ]
    childPath: ['profile'],
    // children: [...settingsNav, ...settingsRoutes],
  },
  {
    name: 'Clients',
    /* navImage: require('../assets/images/clients.png').default, */
    image: require('../assets/images/clients.png').default,
    navIcons: <ClientstIcon />,
    path: 'clients2',
    element: <ClientsCodeRefactoring />,
    // children:[
    //   clientProfile
    // ]
    childPath: ['profile'],
    // children: [...settingsNav, ...settingsRoutes],
  },
  // {
  //   // name: 'Client Profile',
  //   // image: require('../assets/images/dashboard.png').default,
  //   // navIcons: <DashboardIcon />,
  //   path: 'client-profile/:clientId',
  //   element: <ClientDashboard />,
  //   /* isDisabled: isSolo(), */
  // },
  // {
  //   name: 'Reports',
  //   /* navImage: require('../assets/images/reports.png').default, */
  //   image: require('../assets/images/reports.png').default,
  //   navIcons: <ReportstIcon />,
  //   path: 'reports',
  //   element: <Reports />,
  //   /* isDisabled: isSolo(), */
  // },
  // {
  //   name: 'Sales',
  //   /* navImage: require('../assets/images/sales.png').default, */
  //   image: require('../assets/images/sales.png').default,
  //   navIcons: <SalesIcon />,
  //   path: 'sales',
  //   element: <Sales />,
  // },
  {
    name: 'Staff',
    /* navImage: require('../assets/images/staff.png').default, */
    image: require('../assets/images/staff.png').default,
    navIcons: <StaffIcon />,
    path: 'staff',
    element: <Staff />,
    isDisabled: isSolo(),
  },
  {
    name: 'Studios',
    /* navImage: require('../assets/images/studios.jpg').default, */
    image: require('../assets/images/studios.jpg').default,
    navIcons: <StudiosIcon />,
    path: 'studios',
    element: <Studios />,
    isDisabled: isSolo(),
  },
  {
    name: 'Settings',
    /* navImage: require('../assets/images/classes-settings.jpg').default, */
    image: require('../assets/images/classes-settings.jpg').default,
    navIcons: <SettingsIcon />,
    path: 'settings',
    element: (
      <CompanyProvider>
        <TaxDetailsProvider>
          <ClassCategoriesProvider>
            <RevenueCategoriesProvider>
              <CancelPolicyProvider>
                <ClientWaiverProvider>
                  <HoursProvider>
                    <MembershipProvider>
                      <ClassPackProvider>
                        <EmailSettingsProvider>
                          <FitnessClassProvider>
                            <ContractTermsProvider>
                              <Settings />
                            </ContractTermsProvider>
                          </FitnessClassProvider>
                        </EmailSettingsProvider>
                      </ClassPackProvider>
                    </MembershipProvider>
                  </HoursProvider>
                </ClientWaiverProvider>
              </CancelPolicyProvider>
            </RevenueCategoriesProvider>
          </ClassCategoriesProvider>
        </TaxDetailsProvider>
      </CompanyProvider>
    ),
    children: [...settingsNav, ...settingsRoutes],
    listItemStyle: {
      borderTop: '1px #4860CF solid',
    },
  },
];

export const soloClientSettingsNav = [
  {
    name: 'Account Details',
    // image: require('../assets/images/sales.png').default,
    imageIcon: <AccountDetailsIcon />,
    path: 'account-details',
    element: <AccountDetails />,
    isDisabled: false,
  },
  {
    name: 'Notifications',
    // image: require('../assets/images/sales.png').default,
    imageIcon: <NotificationsIcon />,
    path: 'notifications',
    element: <Notifications />,
    isDisabled: false,
  },
  {
    name: 'Packages',
    // image: require('../assets/images/sales.png').default,
    imageIcon: <PackagesIcon />,
    path: 'packages',
    element: <SoloClientPackages />,
    isDisabled: false,
  },
  {
    name: 'Waiver',
    // image: require('../assets/images/sales.png').default,
    imageIcon: <WaiverIcon />,
    path: 'waiver',
    element: <SoloClientWaiver />,
    isDisabled: false,
  },
  {
    name: 'Policies & Procedures',
    // image: require('../assets/images/sales.png').default,
    imageIcon: <PoliciesProceduresIcon />,
    path: 'terms',
    element: <SoloClientTerms />,
    isDisabled: false,
  },
  {
    name: 'Payment',
    // image: require('../assets/images/sales.png').default,
    imageIcon: <PaymentIcon />,
    path: 'payment',
    element: <Payment />,
    isDisabled: false,
  },
];

export const soloClientNav = [
  {
    name: 'My Profile',
    /* navImage: require('../assets/images/calendar.png').default, */
    /* image: require('../assets/images/calendar.png').default, */
    navIcons: <MyProfileIcon />,
    path: 'profile/:clientId',
    element: <ClientDashboard />,
  },
  {
    name: 'Calendar',
    /* navImage: require('../assets/images/calendar.png').default, */
    image: require('../assets/images/calendar.png').default,
    navIcons: <CalendartIcon />,
    path: '',
    element: <UpcomingClasses />,
  },
  {
    name: 'Settings',
    image: require('../assets/images/classes-settings.jpg').default,
    navIcons: <SettingsIcon />,
    path: 'settings',
    element: (
      <CompanyProvider>
        <StateProvider>
          <TaxDetailsProvider>
            <ContractTermsProvider>
              <ClientWaiverProvider>
                <ClassPackProvider>
                  <MembershipProvider>
                    <ClientSignupProvider>
                      <PrivateRoute as={SoloClientSettings} />
                    </ClientSignupProvider>
                  </MembershipProvider>
                </ClassPackProvider>
              </ClientWaiverProvider>
            </ContractTermsProvider>
          </TaxDetailsProvider>
        </StateProvider>
      </CompanyProvider>
    ),
    children: [...soloClientSettingsNav],
    listItemStyle: {
      borderTop: '1px #4860CF solid',
    },
  },
  {
    name: 'Sign Up',
    /* navImage: require('../assets/images/calendar.png').default, */
    image: <ClientWaiverIcon />,
    navIcons: <ClientWaiverIcon />,
    path: 'client-signup',
    element: <ClientSignup />,
  },
];

const widgetNav = [
  {
    path: 'app',
    element: (
      <SoloInstructorProvider>
        <AppUtilitiesProvider>
          <AlertProvider>
            <UnsaveAlertProvider>
              <TimeZoneProvider>
                <StateProvider>
                  <StudioProvider>
                    <StudioListProvider>
                      <PromoCodeProvider>
                        <ValidateSettingsProvider>
                          <ClassInstanceProvider>
                            <StaffMemberProvider>
                              <StaffPayratesProvider>
                                <SoloClient />
                                {/* <PrivateRoute as={SoloClient} /> */}
                              </StaffPayratesProvider>
                            </StaffMemberProvider>
                          </ClassInstanceProvider>
                        </ValidateSettingsProvider>
                      </PromoCodeProvider>
                    </StudioListProvider>
                  </StudioProvider>
                </StateProvider>
              </TimeZoneProvider>
            </UnsaveAlertProvider>
          </AlertProvider>
        </AppUtilitiesProvider>
      </SoloInstructorProvider>
    ),
    children: soloClientNav,
  },
  {
    path: '/app/login',
    element: <LoginWrapper />,
  },
  {
    path: '/app/classes',
    element: <LoginWrapper />,
  },
  {
    path: '/app/check-in/:fitnessSessionId',
    element: <CheckInWrapper />,
  },
  {
    path: '/app/checked-in/:fitnessSessionId',
    element: <CheckInResponse />,
  },
  {
    path: '/app/account-checked-in/:fitnessSessionId/:classInstanceId',
    element: <CheckInResponse />,
  },
  {
    path: '/app/account-sign-up/:fitnessSessionId/:classInstanceId/:emailId',
    element: <ClientSignUpQrCode />,
  },
  {
    path: '/app/forgotPassword',
    element: <ForgotPasswordWrapper />,
  },
  {
    path: '/app/resetPassword/:token',
    element: <ResetPasswordWrapper />,
  },
  {
    path: '/:roleId/login',
    element: <LoginWrapper />,
  },
  {
    path: 'client-signup',
    element: <ClientSignup />,
  },
  {
    path: 'solo-client',
    element: <Abc />,
    children: soloClientSettingsNav,
  },
  {
    path: 'client-signup/edit/:clientSignupId',
    element: <ClientSignup />,
  },
  {
    path: 'calendar',
    element: <UpcomingClasses />,
  },
  {
    path: '/:widgetStudioId/:roleId/login',
    element: <LoginWrapper />,
  },
  {
    path: ':widgetStudioId/client-signup',
    element: <ClientSignup />,
  },
  {
    path: ':widgetStudioId/calendar',
    element: <UpcomingClasses />,
  },
  {
    path: '/app/classes/details/:classInstanceId',
    element: <ABPClasses />,
  },
  /* new update */
  {
    path: '/app/event/book-event/:eventId',
    element: <BookEvent />,
  },
  {
    path: '/app/payment/book-event/:eventId',
    element: <BookEvent />,
  },
  {
    path: '/app/book-event/account-details/:eventId',
    element: <ABIAccountDetails />,
  },
  {
    path: '/app/book-event/packages/:eventId',
    element: <ABIClientPackages />,
  },
  {
    path: '/app/book-event/payment/:eventId',
    element: (
      <SoloInstructorProvider>
        <AlertProvider>
          <ValidateSettingsProvider>
            <TimeZoneProvider>
              <StateProvider>
                <CompaniesProvider>
                  <CompanyProvider>
                    <ABIPayment />
                  </CompanyProvider>
                </CompaniesProvider>
              </StateProvider>
            </TimeZoneProvider>
          </ValidateSettingsProvider>
        </AlertProvider>
      </SoloInstructorProvider>
    ),
  },
  {
    path: '/app/event/booked-event/:eventId',
    element: <BookedEvent />,
  },
  {
    path: '/app/event/cancel-event/:eventId',
    element: <CancelEvent />,
  },
  {
    path: '/app/event/fully-booked/:eventId',
    element: <FullyBookedEvent />,
  },

  /* new update */
  { path: '*', element: <NotFound /> },
];

let appRoutes = [
  {
    path: 'app', // uberadmin
    element: (
      <SoloInstructorProvider>
        <AlertProvider>
          <ValidateSettingsProvider>
            <TimeZoneProvider>
              <StateProvider>
                <CompaniesProvider>
                  <CompanyProvider>
                    <PrivateRoute as={UberDashboard} />
                  </CompanyProvider>
                </CompaniesProvider>
              </StateProvider>
            </TimeZoneProvider>
          </ValidateSettingsProvider>
        </AlertProvider>
      </SoloInstructorProvider>
    ),
  },
  {
    path: ':company/:roleId/app', // solo instrtctor
    element: (
      <ClientSignupProvider>
        <SoloInstructorProvider>
          <AppUtilitiesProvider>
            <AlertProvider>
              <UnsaveAlertProvider>
                <TimeZoneProvider>
                  <StateProvider>
                    <StudioProvider>
                      <StudioListProvider>
                        <PromoCodeProvider>
                          <ValidateSettingsProvider>
                            <ClassInstanceProvider>
                              <StaffMemberProvider>
                                <StaffPayratesProvider>
                                  <PrivateRoute as={Main} />
                                </StaffPayratesProvider>
                              </StaffMemberProvider>
                            </ClassInstanceProvider>
                          </ValidateSettingsProvider>
                        </PromoCodeProvider>
                      </StudioListProvider>
                    </StudioProvider>
                  </StateProvider>
                </TimeZoneProvider>
              </UnsaveAlertProvider>
            </AlertProvider>
          </AppUtilitiesProvider>
        </SoloInstructorProvider>
      </ClientSignupProvider>
    ),
    children: [...appNav, ...soloMainRoutes],
  },
  {
    path: ':company/widget', // solo instrtctor
    element: (
      <AppUtilitiesProvider>
        <AlertProvider>
          <UnsaveAlertProvider>
            <TimeZoneProvider>
              <StateProvider>
                <TaxDetailsProvider>
                  <RevenueCategoriesProvider>
                    <StudioProvider>
                      <StudioListProvider>
                        <PromoCodeProvider>
                          <ValidateSettingsProvider>
                            <ClassInstanceProvider>
                              <StaffMemberProvider>
                                <StaffPayratesProvider>
                                  <ClientSignupProvider>
                                    <MembershipProvider>
                                      <ClassPackProvider>
                                        <ClientWaiverProvider>
                                          <ContractTermsProvider>
                                            <Widget />
                                          </ContractTermsProvider>
                                        </ClientWaiverProvider>
                                      </ClassPackProvider>
                                    </MembershipProvider>
                                  </ClientSignupProvider>
                                </StaffPayratesProvider>
                              </StaffMemberProvider>
                            </ClassInstanceProvider>
                          </ValidateSettingsProvider>
                        </PromoCodeProvider>
                      </StudioListProvider>
                    </StudioProvider>
                  </RevenueCategoriesProvider>
                </TaxDetailsProvider>
              </StateProvider>
            </TimeZoneProvider>
          </UnsaveAlertProvider>
        </AlertProvider>
      </AppUtilitiesProvider>
    ),
    children: widgetNav,
  },
  {
    path: ':company/:roleId/client', // solo client
    element: (
      <AppUtilitiesProvider>
        <AlertProvider>
          <UnsaveAlertProvider>
            <TimeZoneProvider>
              <StateProvider>
                <TaxDetailsProvider>
                  <RevenueCategoriesProvider>
                    <StudioProvider>
                      <StudioListProvider>
                        <PromoCodeProvider>
                          <ValidateSettingsProvider>
                            <ClassInstanceProvider>
                              <StaffMemberProvider>
                                <StaffPayratesProvider>
                                  <ClientSignupProvider>
                                    <MembershipProvider>
                                      <ClassPackProvider>
                                        <ClientWaiverProvider>
                                          <ContractTermsProvider>
                                            <Widget />
                                          </ContractTermsProvider>
                                        </ClientWaiverProvider>
                                      </ClassPackProvider>
                                    </MembershipProvider>
                                  </ClientSignupProvider>
                                </StaffPayratesProvider>
                              </StaffMemberProvider>
                            </ClassInstanceProvider>
                          </ValidateSettingsProvider>
                        </PromoCodeProvider>
                      </StudioListProvider>
                    </StudioProvider>
                  </RevenueCategoriesProvider>
                </TaxDetailsProvider>
              </StateProvider>
            </TimeZoneProvider>
          </UnsaveAlertProvider>
        </AlertProvider>
      </AppUtilitiesProvider>
    ),
    children: widgetNav,
  },
];

switch (userType) {
  case 'solo-instructor':
    appRoutes = [
      {
        path: ':company/:roleId/app', // solo instrtctor
        element: (
          <ClientSignupProvider>
            <SoloInstructorProvider>
              <AppUtilitiesProvider>
                <AlertProvider>
                  <UnsaveAlertProvider>
                    <TimeZoneProvider>
                      <StateProvider>
                        <StudioProvider>
                          <StudioListProvider>
                            <PromoCodeProvider>
                              <ValidateSettingsProvider>
                                <ClassInstanceProvider>
                                  <StaffMemberProvider>
                                    <StaffPayratesProvider>
                                      <PrivateRoute as={Main} />
                                    </StaffPayratesProvider>
                                  </StaffMemberProvider>
                                </ClassInstanceProvider>
                              </ValidateSettingsProvider>
                            </PromoCodeProvider>
                          </StudioListProvider>
                        </StudioProvider>
                      </StateProvider>
                    </TimeZoneProvider>
                  </UnsaveAlertProvider>
                </AlertProvider>
              </AppUtilitiesProvider>
            </SoloInstructorProvider>
          </ClientSignupProvider>
        ),
        children: [...appNav, ...soloMainRoutes],
      },
      {
        path: ':company/widget', // solo instrtctor
        element: (
          <AppUtilitiesProvider>
            <AlertProvider>
              <UnsaveAlertProvider>
                <TimeZoneProvider>
                  <StateProvider>
                    <TaxDetailsProvider>
                      <RevenueCategoriesProvider>
                        <StudioProvider>
                          <StudioListProvider>
                            <PromoCodeProvider>
                              <ValidateSettingsProvider>
                                <ClassInstanceProvider>
                                  <StaffMemberProvider>
                                    <StaffPayratesProvider>
                                      <ClientSignupProvider>
                                        <MembershipProvider>
                                          <ClassPackProvider>
                                            <ClientWaiverProvider>
                                              <ContractTermsProvider>
                                                <Widget />
                                              </ContractTermsProvider>
                                            </ClientWaiverProvider>
                                          </ClassPackProvider>
                                        </MembershipProvider>
                                      </ClientSignupProvider>
                                    </StaffPayratesProvider>
                                  </StaffMemberProvider>
                                </ClassInstanceProvider>
                              </ValidateSettingsProvider>
                            </PromoCodeProvider>
                          </StudioListProvider>
                        </StudioProvider>
                      </RevenueCategoriesProvider>
                    </TaxDetailsProvider>
                  </StateProvider>
                </TimeZoneProvider>
              </UnsaveAlertProvider>
            </AlertProvider>
          </AppUtilitiesProvider>
        ),
        children: widgetNav,
      },
      {
        path: ':company/solo-client/client/app', // solo instrtctor
        element: (
          <ClientSignupProvider>
            <SoloInstructorProvider>
              <AppUtilitiesProvider>
                <AlertProvider>
                  <UnsaveAlertProvider>
                    <TimeZoneProvider>
                      <StateProvider>
                        <StudioProvider>
                          <StudioListProvider>
                            <PromoCodeProvider>
                              <ValidateSettingsProvider>
                                <ClassInstanceProvider>
                                  <StaffMemberProvider>
                                    <StaffPayratesProvider>
                                      <PrivateRoute as={Main} />
                                    </StaffPayratesProvider>
                                  </StaffMemberProvider>
                                </ClassInstanceProvider>
                              </ValidateSettingsProvider>
                            </PromoCodeProvider>
                          </StudioListProvider>
                        </StudioProvider>
                      </StateProvider>
                    </TimeZoneProvider>
                  </UnsaveAlertProvider>
                </AlertProvider>
              </AppUtilitiesProvider>
            </SoloInstructorProvider>
          </ClientSignupProvider>
        ),
        children: [...testNav],
      },
    ];
    break;
  case 'solo-client':
    appRoutes = [
      {
        path: ':company/:roleId/client', // solo client
        element: (
          <AppUtilitiesProvider>
            <AlertProvider>
              <UnsaveAlertProvider>
                <TimeZoneProvider>
                  <StateProvider>
                    <TaxDetailsProvider>
                      <RevenueCategoriesProvider>
                        <StudioProvider>
                          <StudioListProvider>
                            <PromoCodeProvider>
                              <ValidateSettingsProvider>
                                <ClassInstanceProvider>
                                  <StaffMemberProvider>
                                    <StaffPayratesProvider>
                                      <ClientSignupProvider>
                                        <MembershipProvider>
                                          <ClassPackProvider>
                                            <ClientWaiverProvider>
                                              <ContractTermsProvider>
                                                <Widget />
                                              </ContractTermsProvider>
                                            </ClientWaiverProvider>
                                          </ClassPackProvider>
                                        </MembershipProvider>
                                      </ClientSignupProvider>
                                    </StaffPayratesProvider>
                                  </StaffMemberProvider>
                                </ClassInstanceProvider>
                              </ValidateSettingsProvider>
                            </PromoCodeProvider>
                          </StudioListProvider>
                        </StudioProvider>
                      </RevenueCategoriesProvider>
                    </TaxDetailsProvider>
                  </StateProvider>
                </TimeZoneProvider>
              </UnsaveAlertProvider>
            </AlertProvider>
          </AppUtilitiesProvider>
        ),
        children: widgetNav,
      },
    ];
    break;
  default:
    appRoutes = appRoutes;
    break;
}

export default appRoutes;
